import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PolicyEN from '../components/term-and-policy/policy-en'
import PolicyTH from '../components/term-and-policy/policy-th'

const PrivacyPolicy = props => {
  return (
    <Layout>
      <SEO keywords={[`Privacy policy`]} />
      {props.app.lang === 'EN' ? <PolicyEN /> : <PolicyTH />}
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    app: state.app,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicy)
